// import './MyFeed.css';
// import RiskoMeter from '../RiskoMeter/RiskoMeter';
import React, { useState, useEffect, useRef } from 'react';
import Geocode from 'react-geocode';
import GN_Icon from '../../Media/Icons/TopicsIcons/GN_Icon.svg';
import Events_Icon from '../../Media/Icons/TopicsIcons/Events_Icon.svg';
import Research_Icon from '../../Media/Icons/TopicsIcons/Research_Icon.svg';
import Crime_Icon from '../../Media/Icons/TopicsIcons/Crime_Icon.svg';
import Fraud_Icon from '../../Media/Icons/TopicsIcons/Fraud_Icon.svg';
import Politics_Icon from '../../Media/Icons/TopicsIcons/Politics_Icon.svg';
import Initiatives_Icon from '../../Media/Icons/TopicsIcons/Initiatives_Icon.svg';
import Opinions_Icon from '../../Media/Icons/TopicsIcons/Opinions_Icon.svg';
import GN_Icon_Selected from '../../Media/Icons/TopicsIcons/GN_Icon_Selected.svg';
import Events_Icon_Selected from '../../Media/Icons/TopicsIcons/Events_Icon_Selected.svg';
import Research_Icon_Selected from '../../Media/Icons/TopicsIcons/Research_Icon_Selected.svg';
import Crime_Icon_Selected from '../../Media/Icons/TopicsIcons/Crime_Icon_Selected.svg';
import Fraud_Icon_Selected from '../../Media/Icons/TopicsIcons/Fraud_Icon_Selected.svg';
import Politics_Icon_Selected from '../../Media/Icons/TopicsIcons/Politics_Icon_Selected.svg';
import Initiatives_Icon_Selected from '../../Media/Icons/TopicsIcons/Initiatives_Icon_Selected.svg';
import Opinions_Icon_Selected from '../../Media/Icons/TopicsIcons/Opinions_Icon_Selected.svg';
import Health_Icon from '../../Media/Icons/TopicsIcons/icons8-health-80.png';
import Kids_Icon from '../../Media/Icons/TopicsIcons/icons8-kids-64.png';

import Placeholder from '../../Media/Team/placeholder.png';
import { CirclePlus, RotateCw } from 'lucide-react';
// import { User } from '../SinglePostHelper/SinglePostHelper';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton } from 'video-react';
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { makeNumber, timeDiff } from '../../utils/calc';
// import {
// 	EmailShareButton,
// 	EmailIcon,
// 	FacebookShareButton,
// 	FacebookIcon,
// 	LinkedinShareButton,
// 	LinkedinIcon,
// 	RedditShareButton,
// 	RedditIcon,
// 	TwitterShareButton,
// 	TwitterIcon,
// 	WhatsappShareButton,
// 	WhatsappIcon,
// } from 'react-share';
// import ImageModal from '../ImageModal/ImageModal';
// import { useUser } from '../../UserContext';
import { formatDistanceToNow, parseISO } from 'date-fns';
import SharingTab from './SharingTab';
import { imageCacheService } from '../../services/ImageCacheService';
import { useData } from '../../DataContext';
import { Bounce, toast, ToastContainer } from 'react-toastify';

Geocode.setApiKey('AIzaSyBMyRKq-C8PhUUCZDXUg6y1MV4_k523T_8');
Geocode.setLanguage('en');
// Geocode.setRegion('in');
Geocode.setLocationType('ROOFTOP');

type TopicsType = {
	name: string;
	img: string;
	filter: boolean;
	key: number;
};

// Define MediaType and PostType
type MediaType = {
	format: string;
	internalURL: string;
	latitude: string;
	longitude: string;
};

type PostType = {
	id: number;
	title: string;
	media: MediaType[];
	isAnonymous: boolean;
	updatedAt: string;
	createdAt: string;
	commentCount: number;
	likeCount: number;
	tags: string;
	isLiked: number;
	user: { name: string; id: number; profilepicURL: string };
};

export type AllPostDataType = {
	id: number;
	title: string;
	content?: string;
	alteredContent: string;
	isImage: boolean;
	format: string;
	image_url: any;
	media_filename: string;
	name: string;
	location: string;
	time: string;
	commentCount: number;
	likeCount: number;
	tags: string;
	profilepicURL: string;
	isLiked: number;
	youtubeLink: string;
};

interface RiskData {
	No_of_Faces: number;
	No_of_Fire_Objects: number;
	No_of_Persons: number;
	No_of_Weapons: number;
	Risk_Factor: number;
}

// const handleTopicToggle = (
// 	data: TopicsType,
// 	Topics: TopicsType[],
// 	set_Topics: React.Dispatch<React.SetStateAction<TopicsType[]>>
// ) => {
// 	const topics_selected_img_array = [
// 		GN_Icon_Selected,
// 		Events_Icon_Selected,
// 		Research_Icon_Selected,
// 		Crime_Icon_Selected,
// 		Fraud_Icon_Selected,
// 		Politics_Icon_Selected,
// 		Initiatives_Icon_Selected,
// 		Opinions_Icon_Selected,
// 	];
// 	const topics_notselected_img_array = [
// 		GN_Icon,
// 		Events_Icon,
// 		Research_Icon,
// 		Crime_Icon,
// 		Fraud_Icon,
// 		Politics_Icon,
// 		Initiatives_Icon,
// 		Opinions_Icon,
// 	];
// 	set_Topics(
// 		Topics.map((topic) => {
// 			if (topic === data) {
// 				if (topic.filter === false) {
// 					return { ...topic, img: topics_selected_img_array[topic.key - 1], filter: !topic.filter };
// 				} else {
// 					return { ...topic, img: topics_notselected_img_array[topic.key - 1], filter: !topic.filter };
// 				}
// 			} else {
// 				return topic;
// 			}
// 		})
// 	);
// };

// Add these styles at the top of the file
const styles = {
	loadingSpinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '320px',
		backgroundColor: '#f8f9fa',
	} as React.CSSProperties,
	imageContainer: {
		position: 'relative' as const,
		width: '100%',
		height: '320px',
		backgroundColor: '#f8f9fa',
		transition: 'opacity 0.3s ease-in-out',
	} as React.CSSProperties,
	postImage: {
		width: '100%',
		height: '320px',
		objectFit: 'cover',
		display: 'block',
		opacity: 1,
		transition: 'opacity 0.3s ease-in-out',
	} as React.CSSProperties,
};
function MyFeed({ isOpen, toggleSidebar }: { isOpen: boolean; toggleSidebar: () => void }) {
	// const [isOpen, setIsOpen] = useState(false);
	// const { user } = useUser();
	const [Topics, set_Topics] = useState<TopicsType[]>([
		{ name: 'General News', img: GN_Icon, filter: false, key: 1 },
		// { name: 'Events', img: Events_Icon, filter: false, key: 1 },
		{ name: 'Research', img: Research_Icon, filter: false, key: 2 },
		{ name: 'Crime', img: Crime_Icon, filter: false, key: 3 },
		{ name: 'Fraud', img: Fraud_Icon, filter: false, key: 4 },
		{ name: 'Politics', img: Politics_Icon, filter: false, key: 5 },
		{ name: 'Initiatives', img: Initiatives_Icon, filter: false, key: 6 },
		{ name: 'Opinions', img: Opinions_Icon, filter: false, key: 7 },
		{ name: 'Health', img: Health_Icon, filter: false, key: 8 },
		{ name: 'Kidjiu', img: Kids_Icon, filter: false, key: 9 },
	]);

	const [Topic, set_Topic] = useState<number>(0); // Default topic selection
	const location = useLocation();

	const navigate = useNavigate();
	const [AllPostData, set_AllPostData] = useState<AllPostDataType[]>([]);
	const [Time, set_Time] = useState<number>(0);
	const [Distance, set_Distance] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [likes, setLikes] = useState<Record<number, boolean>>({}); // State for likes
	const [riskData, setRiskData] = useState<RiskData | null>(null);
	const [showRiskMeter, setShowRiskMeter] = useState(false);
	const [loadingImages, setLoadingImages] = useState<Record<string, boolean>>({});
	const [isMounted, setIsMounted] = useState(true);
	const { posts, setPosts, profilePicCache, lastRefreshTime } = useData();
	const [isLoading, setIsLoading] = useState(false);
	const [share, setShare] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const componentMountTime = useRef(Date.now());

	// Add new state for tracking visible images
	const [visibleImages, setVisibleImages] = useState<Set<string>>(new Set());
	const observerRef = useRef<IntersectionObserver | null>(null);

	const [searchQuery, setSearchQuery] = useState<string>('');
	const [filteredPosts, setFilteredPosts] = useState<AllPostDataType[]>(AllPostData);
	const [loading, setLoading] = useState<boolean>(false);
	// Initialize intersection observer
	useEffect(() => {
		observerRef.current = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const mediaFilename = entry.target.getAttribute('data-media-filename');
					if (mediaFilename) {
						if (entry.isIntersecting) {
							setVisibleImages((prev) => new Set(prev).add(mediaFilename));
						} else {
							setVisibleImages((prev) => {
								const newSet = new Set(prev);
								newSet.delete(mediaFilename);
								return newSet;
							});
						}
					}
				});
			},
			{ rootMargin: '50px' }
		);

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, []);

	// Topic toggle handler (update images based on selection)
	const handleTopicToggle = (
		data: TopicsType,
		Topics: TopicsType[],
		set_Topics: React.Dispatch<React.SetStateAction<TopicsType[]>>
	) => {
		const topics_selected_img_array = [
			GN_Icon_Selected,
			Events_Icon_Selected,
			Research_Icon_Selected,
			Crime_Icon_Selected,
			Fraud_Icon_Selected,
			Politics_Icon_Selected,
			Initiatives_Icon_Selected,
			Opinions_Icon_Selected,
		];
		const topics_notselected_img_array = [
			GN_Icon,
			Events_Icon,
			Research_Icon,
			Crime_Icon,
			Fraud_Icon,
			Politics_Icon,
			Initiatives_Icon,
			Opinions_Icon,
		];

		set_Topics(
			Topics.map((topic) => {
				if (topic === data) {
					const newImage = topic.filter
						? topics_notselected_img_array[topic.key - 1]
						: topics_selected_img_array[topic.key - 1];

					return { ...topic, img: newImage, filter: !topic.filter };
				}
				return topic;
			})
		);
	};

	const storePreviousLocation = () => {
		localStorage.setItem('previousLocation', '/news');
	};

	// Navigate back to the stored location (e.g., 'My Feed') when the component mounts
	useEffect(() => {
		const previousLocation = localStorage.getItem('previousLocation');
		if (previousLocation) {
			localStorage.removeItem('previousLocation'); // Clear stored location
			navigate(previousLocation); // Navigate back to 'My Feed' or stored location
		}
	}, [navigate]);

	const navigateToPost = (postId: string) => {
		// Store the previous location before navigating to individual post
		storePreviousLocation();
		navigate(`/getpost/${postId}`);
	};

	// Reference to the container
	const postContainerRef = useRef<HTMLDivElement>(null);

	const formatDateToRelativeTime = (dateString: string) => {
		const date = parseISO(dateString);
		return formatDistanceToNow(date, { addSuffix: true });
	};

	const handleRiskometerClick = async (mediaFilename: string) => {
		try {
			const response = await fetch(`http://20.244.37.235:8085/analyze/${mediaFilename}`, {
				method: 'GET',
				redirect: 'follow' as RequestRedirect,
				credentials: 'include' as RequestCredentials,
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			setRiskData(data);
			setShowRiskMeter(true);
		} catch (error) {
			console.error('Error fetching risk data:', error);
		}
	};

	const handleRiskometerClose = () => {
		setShowRiskMeter(false);
	};

	const reactPost = async (postId: number, type: string, isAnonymous: boolean) => {
		const requestOptions: RequestInit = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ postId, type, isAnonymous }),
			credentials: 'include',
		};

		try {
			const response = await fetch('https://backend.b-reporter.com/api/react', requestOptions);

			if (response.status !== 200 && response.status !== 201) {
				// Revert the state changes if the request fails
				setLikes((prevLikes) => ({ ...prevLikes, [postId]: type === 'like' ? false : true }));
				set_AllPostData((prevPosts) =>
					prevPosts.map((post) =>
						post.id === postId ? { ...post, likeCount: post.likeCount + (type === 'like' ? -1 : 1) } : post
					)
				);
			}
		} catch (error) {
			// Revert the state changes if there's an error
			setLikes((prevLikes) => ({ ...prevLikes, [postId]: type === 'like' ? false : true }));
			set_AllPostData((prevPosts) =>
				prevPosts.map((post) =>
					post.id === postId ? { ...post, likeCount: post.likeCount + (type === 'like' ? -1 : 1) } : post
				)
			);
		}
	};

	const handleLikeClick = (postId: number, isAnonymous: boolean) => {
		const isLiked = likes[postId];
		const newType = isLiked ? 'dislike' : 'like';

		// Optimistically update the UI
		setLikes((prevLikes) => ({ ...prevLikes, [postId]: !isLiked }));
		set_AllPostData((prevPosts) =>
			prevPosts.map((post) => (post.id === postId ? { ...post, likeCount: post.likeCount + (isLiked ? -1 : 1) } : post))
		);

		// Perform the API request
		reactPost(postId, newType, isAnonymous);
		console.log(AllPostData);
	};

	// Add cleanup effect
	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	const loadImage = async (mediaUrl: string, priority: number = 0): Promise<string | null> => {
		setLoadingImages((prev) => ({ ...prev, [mediaUrl]: true }));
		try {
			return await imageCacheService.loadImage(mediaUrl, priority);
		} finally {
			if (isMounted) {
				setLoadingImages((prev) => ({ ...prev, [mediaUrl]: false }));
			}
		}
	};

	// Single useEffect to handle data loading
	useEffect(() => {
		const loadPosts = async () => {
			// If this is a fresh page load (component mount time is close to last refresh time)
			const isPageRefresh = Math.abs(componentMountTime.current - lastRefreshTime) < 100;

			if (isPageRefresh) {
				setIsLoading(true);
				await fetchPosts(1);
				setIsLoading(false);
				return;
			}

			// For topic changes or tab switches, use cache if available
			if (posts[Topic] && posts[Topic].length > 0) {
				set_AllPostData(posts[Topic]);
				const initialLikes = posts[Topic].reduce((acc, post) => {
					acc[post.id] = post.isLiked === 1;
					return acc;
				}, {} as Record<number, boolean>);
				setLikes((prevLikes) => ({ ...prevLikes, ...initialLikes }));
				return;
			}

			// If not in cache, fetch from API
			setIsLoading(true);
			await fetchPosts(1);
			setIsLoading(false);
		};

		loadPosts();
	}, [Topic]);

	const fetchContent = async (postId: number): Promise<string> => {
		const requestOptions = {
			method: 'GET',
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials,
		};
		const response = await fetch(`https://backend.b-reporter.com/api/getPost/${postId}`, requestOptions);
		const result = await response.json();

		if (result.body == undefined) return '';
		return result.body as string;
	};

	const fetchLink = async (postId: number): Promise<string> => {
		const requestOptions = {
			method: 'GET',
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials,
		};
		const response = await fetch(`https://backend.b-reporter.com/api/getPost/${postId}`, requestOptions);
		const result = await response.json();

		if (result.media == undefined) return '';
		const url = result.media[0].link;
		console.log(url);
		const regex =
			/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[^\s]*)/;

		const match = url.match(regex);
		console.log(match[1]);
		return match ? `https://www.youtube.com/embed/${match[1]}` : url;
	};

	const fetchPosts = async (page: number) => {
		if (isLoading) return;

		const baseUrl = 'https://backend.b-reporter.com/api/';
		const endpoint = Topic === 0 ? 'myFeed' : 'myFeedPro';
		const url = `${baseUrl}${endpoint}?limit=10&offset=${(page - 1) * 10}${Topic !== 0 ? `&topic=${Topic}` : ''}`;

		try {
			const response = await fetch(url, {
				credentials: 'include',
			});
			const result: PostType[] = await response.json();

			// Async handling of content fetch
			const processedPosts = await Promise.all(
				result.map(async (each) => {
					let isImage = true;
					if (each.media[0].format.substring(0, 5) !== 'image') isImage = false;
					console.log(each);
					const postId = each.id;
					const body = await fetchContent(postId); // Wait for content to resolve
					let youtubeLink = '';
					if (isImage === false) {
						youtubeLink = await fetchLink(postId);
					}
					const truncatedLength = body.length > 65 ? body.slice(0, 65) + '... ' : body;

					return {
						id: each.id,
						title: each.title,
						content: body || '', // Use resolved content here
						alteredContent: truncatedLength,
						isImage: isImage,
						format: each.media[0].format.substring(0, 5),
						image_url: `https://backend.b-reporter.com/api/media/${each.media[0].internalURL}`,
						media_filename: each.media[0].internalURL,
						name: each.isAnonymous ? 'Anonymous' : each.user.name,
						location: `(${each.media[0].latitude}, ${each.media[0].longitude})`,
						time: `${each.createdAt}`,
						commentCount: each.commentCount,
						likeCount: each.likeCount,
						isLiked: each.isLiked,
						tags: each.tags,
						profilepicURL: each.user.profilepicURL,
						youtubeLink: youtubeLink,
					} as AllPostDataType;
				})
			);

			// Filter out null values
			const validPosts = processedPosts.filter((post): post is AllPostDataType => post !== null);

			// Update both local state and context
			if (page === 1) {
				set_AllPostData(validPosts);
				setPosts(Topic, validPosts); // Update context
			} else {
				const newPosts = [...(posts[Topic] || []), ...validPosts];
				set_AllPostData(newPosts);
				setPosts(Topic, newPosts); // Update context
			}

			// Initialize likes state
			const initialLikes = validPosts.reduce((acc, post) => {
				acc[post.id] = post.isLiked === 1;
				return acc;
			}, {} as Record<number, boolean>);
			setLikes((prevLikes) => ({ ...prevLikes, ...initialLikes }));
		} catch (error) {
			console.error('Error fetching posts:', error);
		}
	};

	// const [isOpen, setIsOpen] = useState(false);

	// const toggleSidebar = () => setIsOpen(!isOpen);

	const setExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	const handleTopicClick = (category: TopicsType) => {
		set_Topic(category.key);
		setCurrentPage(1);
		set_AllPostData([]); // Clear existing posts when changing topic
	};

	const handleShareButtonClick = () => {
		setShare(!share);
	};

	const closeSharePopup = () => {
		setShare(false);
	};

	const handleImageLoad = (mediaFilename: string) => {
		setLoadingImages((prev) => ({ ...prev, [mediaFilename]: false }));
	};

	const handleImageError = (mediaFilename: string) => {
		setLoadingImages((prev) => ({ ...prev, [mediaFilename]: false }));
		// Could add error state handling here if needed
	};

	const building = () => {
		toast('We are building this feature. Getting Live Soon !');
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);
		setLoading(true);

		// Filter posts based on the search query in various fields
		const filtered = AllPostData.filter((post) => {
			return (
				post.title.toLowerCase().includes(query) ||
				(post.content && post.content.toLowerCase().includes(query)) ||
				post.alteredContent.toLowerCase().includes(query) ||
				post.tags.toLowerCase().includes(query)
			);
		});
		setFilteredPosts(filtered);
		setLoading(false);
	};

	return (
		<div className='min-h-screen'>
			<div className='md:grid md:grid-cols-4 h-screen w-full'>
				{/* Left Sidebar */}
				{/* <div className='fixed w-1/6 left-0 h-full'>
					<nav className='flex flex-1 flex-col mt-32'>
						{['General News', 'Research', 'Crime', 'Fraud', 'Politics', 'Initiatives', 'Opinions'].map((category, index) => (
								<div key={index}>
									<a
										className={`flex items-center space-x-2 cursor-pointer p-3 rounded-sm hover:bg-gray-200 ${Topic === index ? ' text-blue-700' : 'text-gray-700'
										} no-underline`}
										onClick={() => {
											set_Topic(index);
											setCurrentPage(1);
											set_AllPostData([]);
										}}
									>
										<i className={`fa fa-${category.toLowerCase().replace(' ', '-')}-icon fa-lg`} />
										<span>{category}</span>
									</a>
								</div>
							)
						)}
					</nav>
				</div> */}
				{/* Left Sidebar */}
				<div className='md:col-span-1 h-full hidden md:flex md:flex-col md:items-end'>
					<nav className='flex flex-1 flex-col mt-32'>
						{Topics.map((category, index) => (
							<div key={index}>
								<a
									className={`flex items-center font-mono space-x-2 text-gray-700 hover:text-sky-400 cursor-pointer p-3 rounded-sm ${
										Topic === category.key ? 'text-sky-400' : 'text-gray-700'
									} no-underline`}
									onClick={() => handleTopicClick(category)}
								>
									<img src={category.img} alt={category.name} className='w-6 h-6' />
									<span>
										<b>{category.name}</b>
									</span>
								</a>
							</div>
						))}
					</nav>
				</div>

				{/* Sidebar for smaller screens with sliding effect */}
				<div
					className={`fixed top-0 left-0 w-full h-full bg-gray-100 transition-transform transform duration-500 ease-in-out ${
						isOpen ? 'translate-x-0' : '-translate-x-full'
					} md:p-10 md:hidden z-10`}
				>
					<nav className='flex flex-1 flex-col mt-32'>
						{Topics.map((category, index) => (
							<div key={index}>
								<button
									className={`flex items-center font-mono space-x-2 text-gray-700 hover:text-sky-700 cursor-pointer p-3 rounded-sm ${
										Topic === category.key ? 'text-sky-700' : 'text-gray-700'
									} no-underline`}
									onClick={() => {
										toggleSidebar();
										handleTopicClick(category);
									}}
								>
									<img src={category.img} alt={category.name} className='w-6 h-6' />
									<span>
										<b>{category.name}</b>
									</span>
								</button>
							</div>
						))}
					</nav>
				</div>

				{/* Toggle button for small screens */}
				{/* <div className=''>
					<button onClick={toggleSidebar} className='fixed top-4 left-4 text-black text-3xl z-20 lg:hidden'>
						{isOpen ? '✖' : '☰'}
					</button>
					<button className='fixed top-4 right-4 text-black text-3xl z-20 lg:hidden'>
						<a className='text-black hover:text-blue-400' href='/'>
							Back
						</a>
					</button>
				</div> */}

				{/* Main Feed */}
				<div className='flex flex-col md:col-span-2 items-center w-full h-full mt-32 sm:mt-28'>
					{/* Feed Grid */}
					{/* <div>
						<SharingTab url={'https://play.google.com/store/apps/details?id=com.iitd.breporter3'} title={'Download Link'}/>
					</div> */}
					{/* <div className='p-2'> */}
					<div className='grid grid-cols-1 gap-4 w-11/12 mb-2'>
						<div className='w-full flex flex-row h-10 rounded-md font-roboto border border-gray-900 '>
							<input
								value={searchQuery}
								onChange={handleSearchChange}
								className='w-full p-2 rounded-md bg-white '
								type='text'
								placeholder='Search...'
							/>
							<button className='p-1 w-1/12'>
								<i className='fa-solid fa-search'></i>
							</button>
							<ToastContainer
								position='top-left'
								autoClose={2000}
								hideProgressBar={false}
								newestOnTop={true}
								closeOnClick={false}
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								theme='light'
								transition={Bounce}
							/>
						</div>
					</div>

					{/* </div> */}
					{loading ? (
						<div className='loader'>Loading....</div>
					) : (
						<div className='grid grid-cols-1 gap-4 w-11/12'>
							{(filteredPosts.length > 0 ? filteredPosts : AllPostData).map((data) => (
								<div className='w-full h-full bg-slate-200 border border-gray-900 rounded-md' key={data.id}>
									{/* Post content */}
									<div className='bg-white rounded-md'>
										<div className='flex flex-row gap-4 p-3'>
											<img
												className='w-16 h-16 rounded-full border-2 border-gray-900'
												style={{ objectFit: 'fill' }}
												src={
													data.name === 'Anonymous' ? Placeholder : profilePicCache[data.profilepicURL] || Placeholder
												}
												alt='profile-pic'
											/>
											<div className='flex flex-col items-start text-xl font-bold'>
												<div className='text-xl font-bold'>{data.name}</div>
												<div className='text-lg text-gray-600 font-medium'>
													Posted {formatDateToRelativeTime(data.time)}
												</div>
											</div>
										</div>

										<div
											className='post-img-container'
											style={styles.imageContainer}
											onClick={() => navigateToPost(data.id.toString())}
										>
											<div
												ref={(el) => {
													if (el && observerRef.current) {
														observerRef.current.observe(el);
													}
												}}
												data-media-filename={data.media_filename}
												style={styles.imageContainer}
											>
												{data.isImage &&
													(loadingImages[data.media_filename] ? (
														<div style={styles.loadingSpinner}>
															<div className='spinner-border text-primary' role='status'>
																<span className='visually-hidden'>Loading...</span>
															</div>
														</div>
													) : (
														<img
															src={data.image_url}
															alt='Post'
															style={styles.postImage}
															loading={visibleImages.has(data.media_filename) ? 'eager' : 'lazy'}
															onLoad={() => handleImageLoad(data.media_filename)}
															onError={() => handleImageError(data.media_filename)}
														/>
													))}

												{!data.isImage &&
													(loadingImages[data.media_filename] ? (
														<div style={styles.loadingSpinner}>
															<div className='spinner-border text-primary' role='status'>
																<span className='visually-hidden'>Loading...</span>
															</div>
														</div>
													) : (
														<div className='h-100 w-100 d-flex justify-content-center'>
															<iframe
																src={data.youtubeLink} // Your URL
																width='800' // Set width
																height='300' // Set height
																frameBorder='0' // Optional: to remove border
																title='Example Iframe' // Title for accessibility
															/>
														</div>
													))}
											</div>
										</div>

										<div>
											<p className='text-black font-semibold text-xl text-left ps-2'>{data.title}</p>
											<p className='text-black font-normal text-lg text-left ps-2'>
												{isExpanded ? data.content : data.alteredContent}
												{data.content && data.content?.length > 65 && (
													<button className='font-bold' onClick={setExpanded}>
														{isExpanded ? ' See Less' : ' See More'}
													</button>
												)}
											</p>

											<div className='text-left ps-2'>
												<span
													onClick={() => navigateToPost(data.id.toString())}
													className='text-blue-500 cursor-pointer'
												>
													<b>See Post</b>
												</span>
											</div>

											<div className='flex flex-row p-2 justify-between items-center'>
												<div onClick={() => navigateToPost(data.id.toString())}>
													<i className='bi bi-chat-left' />
													<span> {data.commentCount}</span>
												</div>

												<div onClick={() => handleLikeClick(data.id, data.name === 'Anonymous')}>
													<i
														className={`fa fa-thumbs-up ${likes[data.id] ? 'text-blue-500' : 'text-gray-600'} fa-lg`}
													/>
													<span> {data.likeCount} </span>
												</div>

												<div className='relative'>
													{/* Share button */}
													<div className='p-2'>
														<button onClick={handleShareButtonClick} className='text-lg'>
															<i className='bi bi-share cursor-pointer' />
														</button>
													</div>

													{/* Conditional Rendering of SharingTab */}
													{share && (
														<>
															{/* Backdrop */}
															<div className='fixed  bg-slate-200 bg-opacity-50 z-20 '></div>

															{/* SharingTab Pop-Up */}
															<div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 p-4 bg-white rounded-md'>
																<button
																	onClick={closeSharePopup}
																	className='absolute top-2 right-2 text-xl text-gray-600 hover:text-gray-900'
																>
																	<i className='bi bi-x-circle'></i>
																</button>

																<SharingTab
																	url={`https://b-reporter.com/redirect/post/${data.id}`}
																	title='See the post at '
																/>
															</div>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
							{/* Load More Posts Button */}
							<div className='text-center py-5'>
								<button
									onClick={() => {
										if (!isLoading) {
											setCurrentPage((prevPage) => prevPage + 1);
											fetchPosts(currentPage + 1);
										}
									}}
									className='text-white p-2 font-semibold rounded-md flex cursor-pointer items-center justify-center bottom-2 space-x-2 bg-blue-500 hover:bg-green-500'
								>
									Next
									<i color='white' className='fa fa-chevron-right text-white' />
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default MyFeed;
