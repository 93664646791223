import React, { useState, useEffect } from 'react';
import './AuthorityAdminPanel.css';

const AuthorityAdminPanel = () => {
	const [activeTab, setActiveTab] = useState<'create' | 'delete'>('create');
	const [responseMessage, setResponseMessage] = useState<string>('');
	const [showAlert, setShowAlert] = useState(false);
	// const [showForm, setShowForm] = useState(false);

	const [formData, setFormData] = useState({
		name: '',
		phoneNumber: '',
		verificationDocuments: null as File | null,
	});

	const [authorities, setAuthorities] = useState<{ id: number; name: string }[]>([]);

	// Fetch authorities when Delete tab is active
	useEffect(() => {
		if (activeTab === 'delete') {
			fetchAuthorities();
		}
	}, [activeTab]);

	const fetchAuthorities = async () => {
		try {
			const response = await fetch('https://backend.b-reporter.com/users/list', {
				method: 'GET',
				credentials: 'include',
			});

			const result = await response.json();
			if (response.ok) {
				setAuthorities(result.authorities);
			} else {
				setResponseMessage(result.message || 'Error fetching authorities');
			}
		} catch (error) {
			setResponseMessage('Error fetching authorities');
		}
	};

	const handleDeleteAuthority = async (id: number) => {
		try {
			const response = await fetch(`https://backend.b-reporter.com/users/${id}`, {
				method: 'DELETE',
				credentials: 'include',
			});

			const result = await response.json();
			if (response.ok) {
				setResponseMessage(result.message || 'Authority deleted successfully');
				fetchAuthorities(); // Refresh the list after deletion
			} else {
				setResponseMessage(result.message || 'Error deleting authority');
			}
		} catch (error) {
			setResponseMessage('Error deleting authority');
		}
	};

	const handleCreateAuthority = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// First check if authority with this phone number exists
		try {
			const checkResponse = await fetch('https://backend.b-reporter.com/users/list', {
				method: 'GET',
				credentials: 'include',
			});

			if (!checkResponse.ok) {
				throw new Error('Failed to fetch authorities list');
			}

			const result = await checkResponse.json();
			const phoneNumberToCheck = '91' + formData.phoneNumber;
			
			console.log('Checking phone number:', phoneNumberToCheck);
			console.log('Existing authorities:', result.authorities);
			
			// Check if an authority with this phone number already exists
			const authorityExists = result.authorities.some((authority: any) => {
				console.log('Comparing with:', authority.authority_phoneNumber);
				return authority.authority_phoneNumber?.toString() === phoneNumberToCheck;
			});

			console.log('Authority exists:', authorityExists);

			if (authorityExists) {
				setResponseMessage('An authority with this phone number already exists');
				setShowAlert(true);
				setTimeout(() => {
					setShowAlert(false);
				}, 3000);
				return;
			}

			// If no existing authority found, proceed with creation
			const data = new FormData();
			data.append('name', formData.name);
			data.append('phoneNumber', phoneNumberToCheck);
			if (formData.verificationDocuments) {
				data.append('file', formData.verificationDocuments);
			}

			const response = await fetch('https://backend.b-reporter.com/users/authority/createAuthority', {
				method: 'POST',
				body: data,
				credentials: 'include',
			});

			const createResult = await response.json();
			if (response.ok) {
				setShowAlert(true);
				setTimeout(() => {
					setShowAlert(false);
				}, 3000);
				setResponseMessage(createResult.message || 'Authority created successfully');
				// Clear form after successful creation
				setFormData({
					name: '',
					phoneNumber: '',
					verificationDocuments: null,
				});
				// Refresh the authorities list
				fetchAuthorities();
			} else {
				setResponseMessage(createResult.message || 'Error creating authority');
				setShowAlert(true);
			}
		} catch (error) {
			console.error('Error:', error);
			setResponseMessage('Error checking/creating authority');
			setShowAlert(true);
			setTimeout(() => {
				setShowAlert(false);
			}, 3000);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData((prevData) => ({
			...prevData,
			verificationDocuments: e.target.files ? e.target.files[0] : null,
		}));
	};

	return (
		<div className='admin-panel-bg'>
			<div className='admin-panel-container'>
				<div className='navigation-buttons'>
					<button
						className={`admin-panel-button ${activeTab === 'create' ? 'active' : ''}`}
						onClick={() => setActiveTab('create')}
					>
						Create Authority
					</button>
					<button
						className={`admin-panel-button ${activeTab === 'delete' ? 'active' : ''}`}
						onClick={() => setActiveTab('delete')}
					>
						Delete Authority
					</button>
				</div>

				{activeTab === 'create' && (
					<div>
						{/* <button className="admin-panel-button" onClick={() => setShowForm(true)}>
              Create Authority
            </button> */}

						{/* {showForm && ( */}
						<form onSubmit={handleCreateAuthority}>
							<div className='form-group'>
								<input
									type='text'
									className=''
									name='name'
									placeholder='Enter Name'
									value={formData.name}
									onChange={handleInputChange}
									required
								/>
							</div>
							<div className='form-group'>
								<input
									type='text'
									className=''
									name='phoneNumber'
									placeholder='Enter Phone Number'
									value={formData.phoneNumber}
									onChange={handleInputChange}
									required
								/>
							</div>
							<div className='form-group'>
								<input type='file' className='' name='verificationDocuments' onChange={handleFileChange} required />
							</div>

							<div className='flex flex-col p-4'>
								<select
									defaultValue={'hostel'}
									name='form-type'
									id='form-type'
									className='px-5 py-3 bg-blue-50 text-lg border-2 border-gray-500 rounded-lg shadow-sm focus:outline-none focus:ring-2'
								>
									<option value='hostel'>Hostel Form</option>
									<option value='custom'>Custom Template</option>
								</select>
							</div>

							<button type='submit' className='btn-primary'>
								SUBMIT
							</button>
						</form>
					</div>
				)}

				{activeTab === 'delete' && (
					<div>
						<h3>Authorities List</h3>
						<ul className='authorities-list'>
							{authorities.map((authority) => (
								<li key={authority.id} className='authority-item'>
									<span>
										{authority.name} (ID: {authority.id})
									</span>
									<button className='reject-button' onClick={() => handleDeleteAuthority(authority.id)}>
										Delete
									</button>
								</li>
							))}
						</ul>
					</div>
				)}

				{showAlert && (
					<div className='alert alert-success' role='alert'>
						{responseMessage}
					</div>
				)}
			</div>
		</div>
	);
};

export default AuthorityAdminPanel;
