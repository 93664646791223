import React, { useEffect, useState } from 'react';
import AllPosts from '../../compos/AllPosts/AllPosts';
import { LoginProps } from '../Login/Login';
import { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import Header from '../../compos/Header/Header';
import './News.css';
import { Navigate, useNavigate } from 'react-router-dom';
import MyFeed from '../../compos/AllPosts/MyFeed';
import { useUser } from '../../UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function News() {
	const { user, set_user } = useUser();
	const toggleSidebar = () => setIsOpen(!isOpen);
	const [isOpen, setIsOpen] = useState(false);

	if (user == null) {
		return (
			<div>
				<Navigate to='/login' />
			</div>
		);
	} else {
		return (
			<div className='flex flex-col relative bg-zinc-100 h-full'>
				{/* Toggle button for small screens */}
				<div className='fixed top-0 bg-[#022c56] w-full flex justify-between h-16 px-4 z-20 text-white'>
					<button onClick={toggleSidebar} className='left-4 text-3xl md:hidden'>
						{isOpen ? <FontAwesomeIcon icon={faTimes} />  : <FontAwesomeIcon icon={faBars} />}
					</button>
					<button className='text-2xl md:hidden'>
						<a className='hover:text-blue-400' href='/'>
							Back
						</a>
					</button>
				</div>
				<div className='hidden md:block'>
					<Header /> {/* This will hide the header on screens smaller than "sm" */}
				</div>
				<MyFeed isOpen={isOpen} toggleSidebar={toggleSidebar}/>
			</div>
		);
	}
}

export default News;
