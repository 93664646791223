import React from 'react';

interface ShareButtonProps {
	url: string;
	title: string;
}

const SharingTab: React.FC<ShareButtonProps> = ({ url, title }) => {
	const encodeUrl = encodeURIComponent(url);
	const encodeTitle = encodeURIComponent(title);

	const shareLinks = {
		facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeUrl}`,
		twitter: `https://twitter.com/intent/tweet?text=${encodeTitle}&url=${encodeUrl}`,
		linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeUrl}`,
		whatsapp: `https://api.whatsapp.com/send?text=${encodeTitle} ${encodeUrl}`,
		reddit: `https://www.reddit.com/submit?url=${encodeUrl}&title=${encodeTitle}`,
	};

	const handleShare = (platform: keyof typeof shareLinks) => {
		window.open(shareLinks[platform], 'width=600,height=400');
	};

	return (
		<div className='flex flex-col space-y-4'>
			<button
				onClick={() => handleShare('facebook')}
				className='flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-200'
			>
				<i className='bi bi-facebook'></i>
				<span>Facebook</span>
			</button>

			<button
				onClick={() => handleShare('twitter')}
				className='flex items-center space-x-2 px-4 py-2 bg-blue-400 text-white rounded-lg shadow-md hover:bg-blue-500 transition duration-200'
			>
				<i className='bi bi-twitter'></i>
				<span>Twitter</span>
			</button>

			<button
				onClick={() => handleShare('linkedin')}
				className='flex items-center space-x-2 px-4 py-2 bg-blue-800 text-white rounded-lg shadow-md hover:bg-blue-900 transition duration-200'
			>
				<i className='bi bi-linkedin'></i>
				<span>LinkedIn</span>
			</button>

			<button
				onClick={() => handleShare('whatsapp')}
				className='flex items-center space-x-2 px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 transition duration-200'
			>
				<i className='bi bi-whatsapp'></i>
				<span>WhatsApp</span>
			</button>

			<button
				onClick={() => handleShare('reddit')}
				className='flex items-center space-x-2 px-4 py-2 bg-orange-600 text-white rounded-lg shadow-md hover:bg-orange-700 transition duration-200'
			>
				<i className='bi bi-reddit'></i>
				<span>Reddit</span>
			</button>
		</div>
	);
};

export default SharingTab;
