import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
	const navigate = useNavigate();
	window.onload = function () {
		window.scrollTo(0, 0);
		if (document.body.offsetHeight + 100 < window.innerHeight) {
			document.getElementsByTagName('footer')[0].classList.add('footer-fixed');
		}
	};
	return (
		// <div className='footer d-flex flex-column'>
		//  <div className='container footer-card-container d-flex justify-content-center'>
		//      <div className='card footer-card d-flex align-items-center'>
		//          <div className='col footer-logo d-flex flex-column justify-content-center display-5'>Breporter</div>
		//          <div className='col'>
		//              <label className='footer-newletter-text'>
		//                  Have suggestions? <br /> Write your feedbacks{' '}
		//                  <a
		//                      href='https://docs.google.com/forms/d/e/1FAIpQLSf9gRtB1Cks9UMcQa831Oy8DDWY_Mw0-momrbjvRo5KET7KtA/viewform?usp=sf_link'
		//                      target='_blank'
		//                  >
		//                      here!
		//                  </a>
		//              </label>
		//              <br />
		//              {/* <input type='email' placeholder='Enter email' name='' id='newletter' className='footer-newsletter-email' />
		//              <button className='footer-newletter-button'>Subscribe</button> */}
		//          </div>
		//      </div>
		//  </div>

		//  <div className='container footer-links d-flex mb-3'>
		//      {/* <ul className='d-flex justify-content-evenly'>
		//         <li className="footer-links-items">
		//             T & C s
		//         </li>
		//         <li className="footer-links-items">
		//             About Us
		//         </li>
		//         <li className="footer-links-items">
		//             Privay Policy
		//         </li>
		//     </ul> */}
		//      {/* <div className='col'>T&Cs</div> */}
		//      <Link to='/' style={{ textDecoration: 'none', color: 'white' }} className='col'>
		//              About Us
		//      </Link>
		//      <a
		//          href='https://github.com/CROAM-BREPORTER/Privacy-Policy/blob/main/Privacy-Policy.md'
		//          target='_blank'
		//          className='col'
		//          style={{ textDecoration: 'none', color: 'white' }}
		//      >
		//          Privacy Policy
		//      </a>
		//  </div>
		// </div>
		<footer>
			<div className='bg-[#022c56] text-white p-5 lg:flex lg:justify-between lg:items-center'>
				<div className='lg:text-left lg:w-3/5'>
					<p className='font-semibold'>Copyright © 2024 B-Reporter. All rights reserved</p>
					<p className='text-sm'>
						C/O Vision cowork ,Kh. No. 293 S/F, Western Marg, Saidulajab, Near Kher Singh Estate, Saket, New Delhi -
						110030
					</p>
				</div>
				<div className='flex justify-center items-center'>
					<button
						className='p-2 text-zinc-300 hover:text-white'
						onClick={() => {
							navigate('/policy');
							setTimeout(() => {
								document.getElementsByClassName('privacy-scroll-point')[0].scrollIntoView();
							}, 0);
						}}
					>
						PRIVACY POLICY
					</button>
					<button className='p-2 text-zinc-300 hover:text-white' onClick={() => navigate('/deleteAccount')}>
						DELETE ACCOUNT
					</button>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
