import React from 'react';
import Lottie from 'lottie-react';
import playstore from './google-play.png';
// @ts-ignore
// import { Slide } from 'hero-slider';
// @ts-ignore
// import HeroSlider from 'hero-slider';
// import Pic1 from '../../Media/Icons/pic1.png';
// import Pic2 from '../../Media/Icons/pic2.png';
// import Pic3 from '../../Media/Icons/pic3.png';
// import Pic4 from '../../Media/Icons/pic4.png';

// import bgimage from '../../Media/Images/home_three.jpg';
// import hero from '../../Media/animations/heroAnimation.json';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BasicSlider.css';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import heroVdo from '../../Media/animations/heroVideo.gif';

const BasicSlider = () => {
	const navigate = useNavigate();
	const [isExpanded, setIsExpanded] = useState(false);

	const handleToggle = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const comingSoon = () => {
		toast('We are coming soon in the App Store !');
	};
	return (
		// Slider removed, Hero section from template starts

		<section id='hero' className='hero d-flex align-items-center basic-slider section-bg bg-zinc-100'>
			<ToastContainer
				position='top-left'
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
				transition={Bounce}
			/>
			<div className='container'>
				<div className='row justify-content-between gy-5'>
					<div className='p-20 col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start'>
						<h3 data-aos='fade-up'>
							Be Yourself a Reporter,
							<br />
							With My News, My Report
							<br />
							from B-Reporter.
						</h3>
						<div className='height: 12px;'></div>

						{!isExpanded && (
							<p className='fst-italic'>
								Uncertain about the Place, Time and Source (PTS) of a news floating over social media? Confused with
								agenda based paid, fake news or fact checks? B-Reporter brings <b>location-aware</b> and{' '}
								<b>scene-aware</b> social media platform – My News, My Report...
								<button onClick={handleToggle} className='btn btn-link'>
									Read more
								</button>
							</p>
						)}
						{isExpanded && (
							<p className='fst-italic'>
								Uncertain about the Place, Time and Source (PTS) of a news floating over social media? Confused with
								agenda based paid, fake news or fact checks? B-Reporter brings <b>location-aware</b> and{' '}
								<b>scene-aware</b> social media platform – My News, My Report. It works on the principle of live record
								and report, thus avoiding post-facto and helping the reporter timely if required/possible. Not allowing
								Photoshop and uploading, maintains the sanctity of the report. Bringing ground report from people on
								ground will further add to the authenticity. <br />
								<b>Multimodality</b> gives further flexibility in timely reporting before getting victimized. We are
								committed to connect victim to the authority for instant help, to eventually become victor. It empowers
								every individual to remain alert about happening in surroundings.
								<button onClick={handleToggle} className='btn btn-link'>
									Read less
								</button>
							</p>
						)}
						<div className='d-flex' data-aos='fade-up' data-aos-delay='200'>
							{/* <button
								onClick={() => {
									navigate('/login');
								}}
								className='btn-book-a-table'
							>
								Login
							</button> */}
							<a
								target='_blank'
								className='flex flex-row items-center gap-2 border p-1 rounded-lg bg-white border-gray-300 hover:shadow-md'
								href='https://play.google.com/store/apps/details?id=com.iitd.breporter3'
							>
								<img src={playstore} width={50} />
								<div className='font-roboto text-lg text-black '> Install App</div>
							</a>
							<button
								onClick={comingSoon}
								className='apk-download-container d-flex align-items-center btn-download-apk'
							>
								<i className='fa-brands fa-apple fa-lg	 text-white p-2'></i>
								<span className='hover:text-white'>App Store</span>
							</button>
						</div>
					</div>
					<div className='col-lg-5 order-1 order-lg-2 text-center text-lg-start rounded-full p-20'>
						<img
							src={heroVdo}
							className='img-fluid'
							width='700'
							style={{ mixBlendMode: 'multiply' }}
							alt=''
							data-aos='zoom-out'
							data-aos-delay='300'
						/>
						{/* <Lottie animationData={hero} /> */}
						{/* <iframe width={560} height={315} src={heroVdo} allow='autoplay; loop; encrypted-media;' allowFullScreen /> */}

						{/* <video width='800' height='800' loop autoPlay muted className='shadow-md rounded-full'>
							<source src={heroVdo} type='video/mp4' />
						</video> */}
					</div>
				</div>
			</div>
		</section>
	);
};

export default BasicSlider;
